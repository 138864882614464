const routerSave = {
  state: {
    routerList: []
  },
  mutations: {
    push (state, val) {
      state.routerList.push(val)
      console.log('push进入的数据', state.routerList)
    },
    clear (state) {
      console.log(state.routerList)
      state.routerList = []
    }
  },
  getters: {
    pop (state) {
      const pop = state.routerList.pop()
      console.log('pop的数据', pop)
      return pop
    },
    routerList (state) {
      return state.routerList
    }
  },
  actions: {
    push ({ commit }, val) {
      commit('push', val)
    },
    clear ({ commit }) {
      commit('clear')
    }
  },
  modules: {}
}

export default routerSave
