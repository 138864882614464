<template>
  <page-header-wrapper>
    <router-view />
  </page-header-wrapper>
</template>

<script>

export default {
  name: 'PageView'
}
</script>
<style>
.ant-pro-page-header-wrap-children-content {
  margin: 10px 10px 0;
}
.ant-card-body {
  padding: 10px !important;
}
.table-operator {
  margin-bottom: 10px !important;
}
.ant-table-pagination.ant-pagination {
  margin:5px 0 !important;
}
.table-page-search-wrapper .ant-form-inline .ant-form-item {
  margin-bottom: 10px !important;
}
.ant-table-small {
  border: 1px solid #ffffff !important;
}
.ant-table-small .ant-table-thead {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #e8e8e8;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}

</style>
